import { createLocalVue, shallowMount } from '@vue/test-utils';
import Vuelidate from 'vuelidate';
import Automations from './Automations.vue';

const stubs = {
  HsGroup: true,
  HsTextEditor: true,
  HsForm: true,
  HsIcon: true,
  HsInput: true,
  HsButton: {
    template: '<div class="hs-button-stub"></div>',
    props: ['disabled'],
  },
};

const fcl = {
  canMembersAutomation: () => true,
};

function mountWrapper(localVue, type, automations, hasAutomations) {
  return shallowMount(Automations, {
    localVue,
    stubs,
    mocks: {
      $t: jest.fn().mockImplementation(x => x),
      $FCL: fcl,
      $route: {
        params: {
          contentId: 10,
        },
      },
      $router: {
        push: jest.fn(),
      },
    },
    propsData: {
      type,
      automations,
    },
  });
}

describe('Automations', () => {
  const localVue = createLocalVue();

  it('should render', () => {
    fcl.canMembersAutomation = () => true;
    const wrapper = mountWrapper(localVue, 'file', [], true);
    expect(wrapper.exists()).toBeTruthy();
  });

  it("user don't have automations", () => {
    fcl.canMembersAutomation = () => false;
    const wrapper = mountWrapper(localVue, 'file', [], false);
    wrapper.vm.addNewAutomation();
    expect(wrapper.emitted()['premium'][0][0]).toBe('ContentAutomation');
  });

  it('user have automations', () => {
    fcl.canMembersAutomation = () => true;
    const wrapper = mountWrapper(localVue, 'file', [], true);
    wrapper.vm.addNewAutomation();
    expect(wrapper.emitted()['premium']).toBeUndefined();
  });
});
